import React, {useEffect, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import ReactJson from '@microlink/react-json-view';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

import CustomDrawer from 'components/elements/CustomDrawer';
import CustomTooltip from 'components/elements/CustomTooltip';
import AlertCard from 'components/elements/AlertCard';

import {ExtractionItem} from '../extraction-item';
import EvidenceDetails from '../evidence-details';

const ViewEvidenceDrawer = ({open, handleClose, data, width = '66vw'}) => {
    const {t} = useTranslation();
    const [selectedEvidence, setSelectedEvidence] = useState(null);
    const [viewOriginal, setViewOriginal] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [evidences, setEvidences] = useState([]);


    useEffect(() => {
        setIsReady(false);
        setEvidences(data?.extracted_evidences)
        setSelectedEvidence(data?.extracted_evidences[0])
        setIsReady(true)
    }, [data])


    const handleSelect = (evidence) => {
        setSelectedEvidence(evidence)
    }

    const handleView = () => {
        setViewOriginal((prevState) => !prevState)
    }

    return (
        <CustomDrawer open={open} onclose={handleClose}>
            <Box
                sx={{width, padding: 2}}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} container justifyContent='flex-end' alignItems={'center'}>
                        <Grid item>
                            <IconButton
                                aria-label='close'
                                onClick={handleClose}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container sx={{paddingTop: '0 !important', paddingRight: '1em'}} spacing={2}>

                        {(!isReady || evidences.length === 0) && <Grid item xs={12} container spacing={1}>
                                <AlertCard severity={'info'} message={'No evidence extracted'} height={'auto'}
                                           marginRight={0}/>
                            </Grid>
                        }

                            <Grid item xs={12} container spacing={1}
                                  sx={{overflow: 'auto'}}>
                                <Grid item xs={12} container spacing={1} sx={{height: 'fit-content'}}
                                      flexWrap={'nowrap'}>
                                    {data.extracted_evidences.filter(evidence => evidence.extraction_type !== 'CURATED').map(evidence => (
                                        <ExtractionItem onClick={() => handleSelect(evidence)}
                                                        evidence={evidence}
                                                        selected={evidence.evidence_id === selectedEvidence?.evidence_id}/>
                                    ))}
                                </Grid>
                            </Grid>


                        <Grid item xs={12} container spacing={2}>

                            {!(!isReady || evidences.length === 0) && <Grid item xs={12} container justifyContent={'space-between'}
                                  alignItems={'center'}>
                                <Grid item>
                                </Grid>
                                <Grid item>
                                    <CustomTooltip
                                        title={viewOriginal ? 'View formatted' : 'View original'}>
                                        <IconButton onClick={handleView}><ChangeCircleIcon/></IconButton>
                                    </CustomTooltip>
                                </Grid>
                            </Grid>
                            }

                            <Grid item xs={12} container spacing={1}
                                  sx={{overflow: 'auto', height: 'calc(100vh - 184px)'}}>
                                {!viewOriginal &&
                                    <Grid item xs={12} container spacing={1} sx={{height: 'fit-content'}}>
                                        {selectedEvidence && <EvidenceDetails
                                            extracted_evidences={selectedEvidence.extracted_evidences}/>}
                                    </Grid>}
                                {viewOriginal && <Grid item xs={12}>
                                    <ReactJson src={selectedEvidence.extracted_evidences} name={false}
                                               displayDataTypes={false}
                                               enableClipboard={false}/>
                                </Grid>
                                }
                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>
            </Box>
        </CustomDrawer>
    );
};

export default ViewEvidenceDrawer;