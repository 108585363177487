import {createSlice} from '@reduxjs/toolkit';

export const INITIAL_STATE = {
    activePageTitle: '',
    initData: null,
    vertical: '',
    papersFilters: {
        search: '',
        is_downloaded: '',
        with_abstract: '',
        paper_search_phrase: '',
        is_relevant_paper: '',
        manually_validated: '',
        gpt: '',
        cml: '',
    },
    evidencesPage: {
        selectedPaper:null,
        showPdf: true,
        filters: {
            search: '',
            is_extracted_evidences: 0,
            paper_evidence_status: ''
        }
    },
};

const appSlice = createSlice({
    name: 'app',
    initialState: INITIAL_STATE,
    reducers: {
        notifyChangePageTitle(state, action) {
            state.activePageTitle = action.payload;
        },

        notifyInitData(state, action) {
            state.initData = action.payload;
        },

        notifyChangeVertical(state, action) {
            state.vertical = action.payload;
        },
        notifyUpdateVerticals(state, action) {
            state.initData.verticals = [...state.initData.verticals, action.payload];
        },
        notifyUpdateVertical(state, action) {
            state.initData.verticals = [...state.initData.verticals].map(v => {
                if (v.vertical === action.payload.vertical) {
                    return action.payload
                } else {
                    return v
                }
            });
        },
        notifyDeleteVertical(state, action) {
            state.initData.verticals = [...state.initData.verticals].filter(s => s.vertical !== action.payload)
        },
        notifyChangePapersFilters(state, action) {
            state.papersFilters = action.payload;
        },
        notifyChangeEvidencesFilters(state, action) {
            state.evidencesPage = {...state.evidencesPage, filters: action.payload};
        },
        notifyChangeEvidencesSelectedPaper(state, action) {
            state.evidencesPage = {...state.evidencesPage, selectedPaper: action.payload};
        },
        notifyChangeEvidencesShowPdf(state, action) {
            state.evidencesPage = {...state.evidencesPage, showPdf: action.payload};
        },
    },
});

export const {name, actions, reducer} = appSlice;

export const {
    notifyChangePageTitle,
    notifyInitData,
    notifyChangeVertical,
    notifyUpdateVerticals,
    notifyChangePapersFilters,
    notifyChangeEvidencesFilters,
    notifyChangeEvidencesSelectedPaper,
    notifyChangeEvidencesShowPdf,
    notifyDeleteVertical,
    notifyUpdateVertical
} = actions;

export default reducer;
