import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {isArray} from 'lodash-es';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import {Button} from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

const EvidenceItem = ({name, value}) => {
    return <Grid container item xs={12}>
        <Grid item xs={12}>
            <Typography sx={{
                fontSize: '0.875rem',
                fontWeight: '700',
                color: '#797979',
                textTransform: 'capitalize'
            }}>{name}</Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography sx={{fontSize: '0.875rem'}}>{value}<span
                style={{paddingLeft: '0.5em', verticalAlign: 'middle', cursor: 'pointer'}}>
                <CopyToClipboard text={value}>
                    <IconButton sx={{padding: '0.1em'}}>
                <ContentCopyIcon
                    sx={{fontSize: '0.875rem'}}/>
                    </IconButton>
                </CopyToClipboard>
            </span></Typography>
        </Grid>
    </Grid>
}

const GptResponse = ({value}) => {
    const [details, setDetails] = useState(false)
    const handleViewDetails = () => {
        setDetails((prevState) => !prevState)
    }
    return <Grid container item xs={12}>
        <Grid item xs={12}>
            <Alert
                severity="warning"
                action={
                    <Button color="inherit" size="small" sx={{textTransform: 'none'}} onClick={handleViewDetails}>
                        {details ? 'Hide' : 'Details'}
                    </Button>
                }
            >
                Failed to extract evidence
            </Alert>
        </Grid>
        {details && <Grid item xs={12}>
            <Alert icon={false} severity="warning">
                {value}
            </Alert>
        </Grid>
        }
    </Grid>
}

const RenderObject = ({item}) => {
    return <>
        {item !== null && typeof item !== 'object' && !isArray(item) && item}
        {item !== null && typeof item === 'object' && !isArray(item) && <>
            {Object.keys(item).map((key) => <>
                {item[key] !== null && typeof item[key] === 'object' && !isArray(item[key]) && <>
                    <RenderObject item={item[key]}/>
                </>}
                {item[key] !== null && typeof item[key] !== 'object' && !isArray(item[key]) && <>
                    <RenderObjectValues item={key} value={item[key]}/>
                </>}
            </>)}
        </>}
    </>
}

const RenderObjectValues = ({item, value}) => {
    return <span style={{paddingRight: '1em'}}><span style={{
        fontWeight: '700',
        textTransform: 'capitalize'
    }}>{item}</span>: {value}</span>
}

const OutcomeItem = ({name, value}) => {

    function haveSameKeys(arr) {
        if (!Array.isArray(arr) || arr.length === 0) return false;

        const firstKeys = Object.keys(arr[0]);

        return arr.every(obj => {
            const currentKeys = Object.keys(obj);
            return firstKeys.length === currentKeys.length &&
                firstKeys.every(key => currentKeys.includes(key));
        });
    }

    function isObjectArray(arr) {
        return Array.isArray(arr) && arr.every(item => typeof item === 'object' && item !== null);
    }

    return <Grid container item xs={12}>
        <Grid item xs={12}>
            <Typography sx={{fontSize: '0.875rem',}}>

                {value && typeof value !== 'object' && !isArray(value) &&
                    <span>
                        <span style={{
                            fontWeight: '700',
                            color: '#797979',
                            textTransform: 'capitalize'
                        }}>{name}: </span>
                        {value}
                        <span
                            style={{paddingLeft: '0.5em', verticalAlign: 'middle', cursor: 'pointer'}}>
                    <CopyToClipboard text={value}>
                    <IconButton sx={{padding: '0.1em'}}>
                        <ContentCopyIcon sx={{fontSize: '0.875rem'}}/>
                    </IconButton>
                    </CopyToClipboard>
                    </span>
                </span>
                }
                {typeof value === 'object' && !Array.isArray(value) && value !== null && <>
                    <span style={{
                        fontWeight: '700',
                        color: '#797979',
                        textTransform: 'capitalize'
                    }}>{name}<br/></span>
                    <Grid container columnSpacing={3} rowSpacing={0.5}>
                        {Object.keys(value).map((key) => {
                            return <Grid container item xs={6} justifyContent={'space-between'}>
                                <Grid item> <span style={{
                                    fontWeight: '700',
                                    textTransform: 'capitalize'
                                }}>{key}</span>
                                </Grid><Grid item> {value[key]}</Grid></Grid>
                        })}
                    </Grid>
                    {/*{JSON.stringify(value, null, '')}*/}
                </>}

                {isArray(value) && <>
                    <span style={{
                        fontWeight: '700',
                        color: '#797979',
                        textTransform: 'capitalize'
                    }}>{name}<br/></span>

                    {isObjectArray(value) && haveSameKeys(value) && <>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    {Object.keys(value[0]).map((key) => <TableCell><span style={{
                                        fontWeight: '700',
                                        textTransform: 'capitalize'
                                    }}>{key}</span></TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody id="data-table">
                                {Array.from(value).map(item => <TableRow>
                                        {Object.keys(item).map((key) => <TableCell>
                                            <RenderObject item={item[key]}/>
                                        </TableCell>)}
                                    </TableRow>
                                )}

                            </TableBody>
                        </Table>
                    </>}

                    {isObjectArray(value) && !haveSameKeys(value) && Array.from(value).map(item => {
                        if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
                            return <>
                                {Object.keys(item).map((key) => {
                                    return <RenderObjectValues item={key} value={item[key]}/>
                                })}
                                <br/> </>
                        } else {
                            return <>
                            </>
                        }
                    })}

                    {!isObjectArray(value) && !haveSameKeys(value) && JSON.stringify(value, null, '')}
                </>}
            </Typography>
        </Grid>
    </Grid>
}

const OutcomeRenderer = ({outcome}) => {
    const variables = [
        {
            name: 'outcome_variable',
            keys: [
                "outcome_variable", "Outcome variable", "outcome variable", "Outcome_variable", "outcome"
            ]
        },
        {
            name: 'metric_name',
            keys: [
                "metric name", "metric_name", "Metric name", "Metric_name"
            ]
        },
        {
            name: 'effect_sizes',
            keys: [
                "effect sizes", "effect_sizes", "Effect sizes", "effect_size", "Effect_size"
            ]
        },
        {
            name: 'effect_sizes_post',
            keys: [
                "effect sizes post", "effect_sizes_post", "Effect sizes post"
            ]
        },
        {
            name: 'effect_sizes_pre',
            keys: [
                "effect sizes pre", "effect_sizes_pre", "Effect sizes pre"
            ]
        },
        {
            name: 'p_value',
            keys: [
                "p_value", "p-value", "P_value"
            ]
        },
        {
            name: 'confidence_interval',
            keys: [
                "confidence interval", "confidence_interval", "Confidence_interval"
            ]
        },
    ]

    return <>
        {variables.map(variable => (<>
                {variable.name === 'outcome_variable' && variable.keys.map((key) => outcome[key] && <OutcomeItem
                    name={'Outcome Variable'} value={outcome[key]}/>)}

                {variable.name === 'metric_name' && variable.keys.map((key) => outcome[key] && <OutcomeItem
                    name={'Metric Name'} value={outcome[key]}/>)}

                {variable.name === 'effect_sizes' && variable.keys.map((key) => outcome[key] && <OutcomeItem
                    name={'Effect Sizes'} value={outcome[key]}/>)}

                {variable.name === 'effect_sizes_post' && variable.keys.map((key) => outcome[key] && <OutcomeItem
                    name={'Effect Sizes Post'} value={outcome[key]}/>)}

                {variable.name === 'effect_sizes_pre' && variable.keys.map((key) => outcome[key] && <OutcomeItem
                    name={'Effect Sizes Pre'} value={outcome[key]}/>)}

                {variable.name === 'p_value' && variable.keys.map((key) => outcome[key] && <OutcomeItem
                    name={'P Value'} value={outcome[key]}/>)}

                {variable.name === 'confidence_interval' && variable.keys.map((key) => outcome[key] &&
                    <OutcomeItem
                        name={'Confidence Interval'} value={outcome[key]}/>)}
            </>)
        )}
        <Grid item xs={12}>
            <Divider sx={{borderBottom: '0.15em solid rgb(233, 233, 233)'}}/>
        </Grid>
    </>
}

const OutcomeMetrics = ({data}) => {

    return <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
            <Typography sx={{
                fontSize: '0.875rem',
                fontWeight: '700',
                color: '#797979',
                textTransform: 'capitalize'
            }}>Outcome Metrics</Typography>
        </Grid>
        <Grid item xs={12} container spacing={1}>
            {isArray(data) && data.map((outcome) => {
                return <OutcomeRenderer outcome={outcome}/>
            })}
            {typeof data === 'object' && !isArray(data) && <OutcomeRenderer outcome={data}/>}
        </Grid>
    </Grid>
}

const EvidenceDetails = ({extracted_evidences}) => {
    const keys = ['research_objective', 'study_design', 'treatment_used', 'population_characteristics', 'population', 'outcome_metrics']

    return <Grid item xs={12} container spacing={1} sx={{height: 'fit-content'}}>
        {isArray(extracted_evidences) && extracted_evidences.map(evidence => (Object.keys(evidence).map((key, index) => {
                if (key === 'GPT Response') {

                } else {
                    return <Grid item xs={12}>
                        <EvidenceItem name={key} value={evidence[key]}/>
                    </Grid>
                }

            })
        ))}

        {!isArray(extracted_evidences) &&
            <>
                {Object.keys(extracted_evidences).includes('GPT Response') ?
                    <GptResponse value={extracted_evidences['GPT Response']}/> :
                    <>
                        {
                            extracted_evidences['research_objective'] && typeof extracted_evidences['research_objective'] === 'string' &&
                            <EvidenceItem name={'Objective'}
                                          value={extracted_evidences['research_objective']}/>
                        }
                        {
                            extracted_evidences['study_design'] && typeof extracted_evidences['study_design'] === 'string' &&
                            <EvidenceItem name={'Study Design'}
                                          value={extracted_evidences['study_design']}/>
                        }
                        {
                            extracted_evidences['treatment_used'] && typeof extracted_evidences['treatment_used'] === 'string' &&
                            <EvidenceItem name={'Treatment'}
                                          value={extracted_evidences['treatment_used']}/>
                        }
                        {
                            extracted_evidences['population_characteristics'] && typeof extracted_evidences['population_characteristics'] === 'string' &&
                            <EvidenceItem name={'Population Characteristics'}
                                          value={extracted_evidences['population_characteristics']}/>
                        }
                        {
                            extracted_evidences['population'] && typeof extracted_evidences['population'] === 'string' &&
                            <EvidenceItem name={'Population'}
                                          value={extracted_evidences['population']}/>
                        }

                        {
                            extracted_evidences['outcome_metrics'] &&
                            <OutcomeMetrics
                                data={extracted_evidences['outcome_metrics']}/>
                        }

                        {Object.keys(extracted_evidences).map((key, index) => {
                            return <>
                                {typeof extracted_evidences[key] !== 'object' && extracted_evidences[key] !== null && !keys.includes(key)
                                    && typeof extracted_evidences[key] === 'string' && <Grid item xs={12}>
                                        <EvidenceItem name={key}
                                                      value={extracted_evidences[key]}/>
                                    </Grid>}
                            </>
                        })}
                    </>
                }
            </>
        }
    </Grid>
}

export default EvidenceDetails