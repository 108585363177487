import React, {useEffect, useRef, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import {Card} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Link from '@mui/material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ReactJson from '@microlink/react-json-view';
import DeleteIcon from '@mui/icons-material/Delete';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import {useDispatch, useSelector} from 'react-redux';

import AlertCard from 'components/elements/AlertCard';
import CustomButton from 'components/elements/CustomButton';
import CustomTooltip from 'components/elements/CustomTooltip';
import PdfViewer from 'components/elements/PdfViewer';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import useEvidenceDelete from 'hooks/evidences/useEvidenceDelete';
import {notifyChangeEvidencesShowPdf} from 'state/app';

import EditEvidenceDialog from './editEvidence/edit-evidence-dialog';
import EvidenceDetails from './evidence-details';
import {ExtractionItem} from './extraction-item';

const EvidenceCardView = ({data, height, handleRefetch}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const viewer = useRef(null);
    const instance = useRef(null);
    const showPdf = useSelector((state) => state.app.evidencesPage.showPdf);
    const [evidences, setEvidences] = useState([]);
    const [selectedEvidence, setSelectedEvidence] = useState(null);
    const [evidenceEditing, setEvidenceEditing] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [viewOriginal, setViewOriginal] = useState(false);
    const [evidenceDeleting, setEvidenceDeleting] = useState(null);

    const sourceRef = useRef();

    const {mutateAsync} = useEvidenceDelete();

    const evidenceDeleteOpen = Boolean(evidenceDeleting);

    useEffect(() => {
        setIsReady(false);
        setEvidences(data?.extracted_evidences)
        setSelectedEvidence(data?.extracted_evidences[0])
        setIsReady(true)
    }, [data])

    const handleSelect = (evidence) => {
        setSelectedEvidence(evidence)
    }

    const handleDrawerOpen = (event) => {
        event.stopPropagation();
        dispatch(notifyChangeEvidencesShowPdf(true));
    }

    const handleDrawerClose = (event) => {
        event.stopPropagation();
        dispatch(notifyChangeEvidencesShowPdf(false));
    }

    const handleViewSource = () => {
        if (sourceRef && sourceRef.current) {
            sourceRef.current.click();
        }
    }

    const handleEdit = (evidence) => {
        setEvidenceEditing(evidence)
    }

    const handleEditClose = (value) => {
        setEvidenceEditing(null)
        if (value) {
            handleRefetch()
        }
    }

    const handleView = () => {
        setViewOriginal((prevState) => !prevState)
    }

    const handleDelete = (evidenceId) => {
        mutateAsync({evidenceId}).then(() => {
            setEvidenceDeleting(null);
            handleRefetch()
        })
    }

    const handleDeleteOpen = (evidence) => {
        setEvidenceDeleting(evidence)
    }

    const handleDeleteClose = () => {
        setEvidenceDeleting(null)
    }

    return (
        <Grid item xs={12} sx={{height}}>
            <Card sx={{padding: 2, height: height - 8}}>
                <Grid container spacing={2}>
                    <Grid item container xs={12} justifyContent={'flex-end'} spacing={1} columnSpacing={3}>
                        <Grid item>
                            <Link href={data.provider_url} target={"_blank"} sx={{display: 'none'}}
                                  ref={sourceRef}/>
                            <CustomButton sx={{color: '#656565'}}
                                          startIcon={<OpenInNewIcon sx={{color: '#0794d3'}}/>}
                                          onClick={handleViewSource}>
                                {t('View Source')}
                            </CustomButton>
                        </Grid>
                        <Grid item>
                            {showPdf ?
                                <IconButton>
                                    <UnfoldLessIcon sx={{transform: 'rotate(90deg)'}} onClick={handleDrawerClose}/>
                                </IconButton>
                                :
                                <IconButton>
                                    <UnfoldMoreIcon sx={{transform: 'rotate(90deg)'}} onClick={handleDrawerOpen}/>
                                </IconButton>
                            }
                        </Grid>
                    </Grid>
                    {!isReady || evidences.length === 0 && <Grid item xs={12} container spacing={1}>
                        <AlertCard severity={'info'} message={'No evidence extracted'} height={'auto'} marginRight={0}/>
                    </Grid>}
                    {isReady && evidences.length > 0 && <Grid item xs={12} container spacing={1}>
                        <Grid item xs={12} container spacing={1} flexWrap={'nowrap'}
                              sx={{height: 'fit-content', paddingTop: '0 !important', overflow: 'auto'}}>
                            {/*<ExtractionPdf onClick={handleSelectPdf}*/}
                            {/*               width={2}*/}
                            {/*               selected={pdf}/>*/}
                            {evidences.map(evidence => (
                                <> {evidence && <ExtractionItem onClick={() => handleSelect(evidence)}
                                                                evidence={evidence}
                                                                width={2}
                                                                selected={evidence.evidence_id === selectedEvidence?.evidence_id}/>}</>
                            ))}
                        </Grid>
                        <Grid item xs={12} sx={{paddingBottom: '16px'}}>
                            <Divider sx={{borderBottom: '0.125em solid rgb(233, 233, 233)'}}/>
                        </Grid>
                        <Grid container spacing={2} item xs={12}
                              sx={{paddingTop: '0 !important'}}>
                            <Grid item xs={12} container spacing={1}
                                  sx={{height: 'fit-content'}}>

                                {selectedEvidence && <Grid item xs={12} container justifyContent={'flex-end'}>
                                    <Grid item>
                                        <CustomTooltip title={viewOriginal ? 'View formatted' : 'View original'}>
                                            <IconButton onClick={handleView} sx={{color: '#656565'}}><ChangeCircleIcon
                                                fontSize={'small'}/></IconButton>
                                        </CustomTooltip>
                                    </Grid>
                                    {selectedEvidence.extraction_type !== 'CHAT_GPT' &&
                                        <Grid item>
                                            <CustomTooltip title={'Edit evidence'}>
                                                <IconButton sx={{color: '#656565'}}
                                                            onClick={() => handleEdit(selectedEvidence)}><EditIcon
                                                    fontSize={'small'}/></IconButton>
                                            </CustomTooltip>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <CustomTooltip title={'Delete evidence'}>
                                            <IconButton sx={{color: '#656565'}}
                                                        onClick={() => handleDeleteOpen(selectedEvidence)}><DeleteIcon
                                                fontSize={'small'}/></IconButton>
                                        </CustomTooltip>
                                    </Grid>
                                </Grid>}
                                {selectedEvidence && <Grid item xs={12} container
                                                           sx={{height: `calc(${height - 188}px)`, overflow: 'auto',}}>
                                    {!viewOriginal &&
                                        <Grid item xs={12} container spacing={1} sx={{height: 'fit-content'}}>
                                            {selectedEvidence &&
                                                <EvidenceDetails
                                                    extracted_evidences={selectedEvidence.extracted_evidences}/>}
                                        </Grid>}
                                    {viewOriginal && <Grid item xs={12}>
                                        <ReactJson src={selectedEvidence.extracted_evidences} name={false}
                                                   displayDataTypes={false}
                                                   enableClipboard={false}/>
                                    </Grid>
                                    }
                                </Grid>
                                }

                                {!selectedEvidence && <Grid item xs={12} container sx={{
                                    height: `calc(${height - 152}px)`,
                                    overflow: 'auto',
                                }}>
                                    <Grid item xs={12}>
                                        <PdfViewer paperId={data['paper_id']} paperName={data['paper_name']} viewer={viewer} instance={instance}/>
                                    </Grid>
                                </Grid>
                                }

                            </Grid>
                        </Grid>
                    </Grid>}
                </Grid>
                {evidenceEditing &&
                    <EditEvidenceDialog open={evidenceEditing} handleClose={handleEditClose} height={300}/>}
                <>{evidenceDeleteOpen && <ConfirmationDialog open={evidenceDeleteOpen}
                                                             onConfirm={() => handleDelete(evidenceDeleting['evidence_id'])}
                                                             title={`Delete evidence`}
                                                             context={`Are you sure want to delete evidence?`}
                                                             confirmText={'Delete'}
                                                             onClose={handleDeleteClose}/>}</>
            </Card>
        </Grid>

    );
};

export default EvidenceCardView;
